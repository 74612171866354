export default class E2ETestingTools {
    static active = () => {
        const e2eSessionItem = sessionStorage.getItem('e2etesting_tools')
        if (e2eSessionItem) {
            return true
        }
        return false
    }

    static setEmailVerification = (emailVerification: string) => {
        sessionStorage.setItem('emailVerification', emailVerification)
    }

    static clearE2EValues = () => {
        sessionStorage.removeItem('userRequest')
        sessionStorage.removeItem('emailVerification')
    }
}
