import { NonFormikFormLayout } from '@/components/Shared/NonFormikFormLayout'
import {
    CustomerIcon,
    InformationIcon,
    LockIcon,
    MidmarkButton,
    MidmarkInlineNotification,
    MidmarkTypography,
} from '@midmark-enterprise/shared-components'
import { Avatar, Grid } from '@mui/material'
import { grey } from '@mui/material/colors'
import { useTranslation } from 'react-i18next'

type AccountCreatedStepProps = {}

export default function AccountCreatedStep(
    props: AccountCreatedStepProps,
): JSX.Element {
    const { t } = useTranslation()

    return (
        <>
            <NonFormikFormLayout
                title={t('userSignUpStepper.steps.accountCreatedStep.title')}
                description={t('userSignUpStepper.steps.accountCreatedStep.description')}
                buttons={
                    <>
                        <MidmarkButton
                            data-testid={DATA_TESTIDS.getStartedButton}
                            type="submit"
                            href={import.meta.env.VITE_MIDMARK_PORTAL_URL}
                        >
                            {t('userSignUpStepper.steps.accountCreatedStep.getStartedButton')}
                        </MidmarkButton>
                    </>
                }
            >
                <Grid container alignItems="center" wrap="nowrap" mb={3}>
                    <Avatar
                        sx={{
                            backgroundColor: grey[300],
                            marginRight: '10px',
                            width: 56,
                            height: 56,
                        }}
                    >
                        <LockIcon color="primary" />
                    </Avatar>
                    <MidmarkTypography data-testid={DATA_TESTIDS.signInText}>
                        {t('userSignUpStepper.steps.accountCreatedStep.signInText')}
                    </MidmarkTypography>
                </Grid>
                <Grid container alignItems="center" wrap="nowrap" mb={2}>
                    <Avatar
                        sx={{
                            backgroundColor: grey[300],
                            marginRight: '10px',
                            width: 56,
                            height: 56,
                        }}
                    >
                        <CustomerIcon color="primary" />
                    </Avatar>
                    <MidmarkTypography
                        data-testid={DATA_TESTIDS.provideInfoText}
                    >
                        {t('userSignUpStepper.steps.accountCreatedStep.provideInfoText')}
                    </MidmarkTypography>
                </Grid>

                <MidmarkInlineNotification
                    sx={{ mt: 1, borderLeft: '0px' }}
                    severity="info"
                    icon={<InformationIcon />}
                    data-testid={DATA_TESTIDS.infoNotification}
                    forceOpen={true}
                >
                    {t('userSignUpStepper.steps.accountCreatedStep.infoNotification')}
                </MidmarkInlineNotification>
            </NonFormikFormLayout>
        </>
    )
}

export const DATA_TESTIDS = {
    getStartedButton: 'get-started-button',
    infoNotification: 'info-notification',
    signInText: 'sign-in-text',
    provideInfoText: 'provide-info-text',
}
