import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import {
    MidmarkButton,
    MidmarkFormikTextField,
    MidmarkInlineNotification,
    MidmarkLink,
    VisibilityIcon,
    VisibilityOffIcon,
    ErrorIcon,
    VerificationServiceType,
} from '@midmark-enterprise/shared-components'
import IconButton from '@mui/material/IconButton'
import { FormLayout } from '@/components/Shared/FormLayout'
import { UserRegistrationDataContext } from '../user-signup-modal.component'
import Recaptcha from '@/components/Shared/Recaptcha/Recaptcha'
import { useTranslation } from 'react-i18next'

const oneSpecialCharacterReg = new RegExp(
    /^(?=.*[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]).{1,}$/,
)
const oneLetterReg = new RegExp(/[a-z,A-Z]/)
const validEmailReg = new RegExp(
    /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
)

export const buildSchema = (t: (key: string) => string) => {
    return Yup.object().shape({
        firstName: Yup.string().required(t('userSignUpStepper.steps.signUpForm.requiredFirstName')),
        lastName: Yup.string().required(t('userSignUpStepper.steps.signUpForm.requiredLastName')),
        email: Yup.string()
            .email()
            .matches(validEmailReg, t('userSignUpStepper.steps.signUpForm.invalidEmail'))
            .required(t('userSignUpStepper.steps.signUpForm.requiredEmail')),
        password: Yup.string()
            .required(t('userSignUpStepper.steps.signUpForm.passwordRequired'))
            .min(10, t('userSignUpStepper.steps.signUpForm.passwordMatchCriteria'))
            .matches(/\d/, t('userSignUpStepper.steps.signUpForm.passwordMatchNumbers'))
            .matches(oneLetterReg, t('userSignUpStepper.steps.signUpForm.passwordMatchOneLetter'))
            .matches(
                oneSpecialCharacterReg,
                t('userSignUpStepper.steps.signUpForm.passwordMatchSpecialCharacter'),
            )
            .oneOf([Yup.ref('confirmPassword')], t('userSignUpStepper.steps.signUpForm.passwordsMustMatch')),
        confirmPassword: Yup.string().required(t('userSignUpStepper.steps.signUpForm.passwordConfirmPasswordIsRequired')),
    })
}

export interface SignUpFormProps {}

export default function SignUpForm({
    handleSubmit,
}: {
    handleSubmit: (
        values: UserRegistrationFormData,
        action: {
            setSubmitting: (submitting: boolean) => void
            setFieldError: (field: string, errorMessage: string) => void
        },
    ) => void
    }) {
    const { t } = useTranslation()
    const [passwordVisible, setPasswordVisible] = useState(false)
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)
    const [showNotification, setShowNotification] = useState(false)
    const [notificationText, setShowNotificationText] = useState('')
    const [notificationStatus, setNotificationStatus] = useState('info')
    const [iAmNotARobot, setIAmNotARobot] = useState<boolean>(false)
    const [showRecaptchaRequiredMessage, setShowRecaptchaRequiredMessage] =
        useState(false)

    const portalUrl = useMemo(() => {
        const portalUrl = new URL(import.meta.env.VITE_MIDMARK_PORTAL_URL)
        return portalUrl.toString()
    }, [])
    const userRegistrationData = useContext(UserRegistrationDataContext)

    const togglePasswordVisibility = useCallback(() => {
        setPasswordVisible(!passwordVisible)
    }, [passwordVisible])

    const toggleConfirmPasswordVisibility = useCallback(() => {
        setConfirmPasswordVisible(!confirmPasswordVisible)
    }, [confirmPasswordVisible])

    const validate = (values: UserRegistrationFormData) => {
        if (
            values.firstName.trim() === '' ||
            values.lastName.trim() === '' ||
            values.email.trim() === ''
        ) {
            setShowNotification(true)
            setNotificationStatus('error')
            setShowNotificationText(t('userSignUpStepper.steps.signUpForm.completeRequiredFields'))
        }

        if (values.password !== '') {
            if (values.password.length < 10) {
                setShowNotification(true)
                setNotificationStatus('info')
                setShowNotificationText(
                    t('userSignUpStepper.steps.signUpForm.passwordRequirements'),
                )
            }
        }
    }

    useEffect(() => {
        setShowNotification(true)
        setNotificationStatus('info')
        setShowNotificationText(
            t('userSignUpStepper.steps.signUpForm.passwordRequirements'),
        )
    }, [])

    return (
        <Formik
            initialValues={{
                email: userRegistrationData?.userRegistrationData?.email ?? '',
                firstName:
                    userRegistrationData?.userRegistrationData?.firstName ?? '',
                lastName:
                    userRegistrationData?.userRegistrationData?.lastName ?? '',
                password:
                    userRegistrationData?.userRegistrationData?.password ?? '',
                confirmPassword:
                    userRegistrationData?.userRegistrationData
                        ?.confirmPassword ?? '',
            }}
            validationSchema={buildSchema(t)}
            validateOnChange={true}
            validateOnBlur={true}
            validate={validate}
            onSubmit={handleSubmit}
        >
            {(props) => (
                <FormLayout
                    title={t('userSignUpStepper.steps.signUpForm.title')}
                    description={t('userSignUpStepper.steps.signUpForm.description')}
                    buttons={
                        <>
                            <MidmarkLink
                                variant="body1"
                                component="a"
                                href={portalUrl}
                                data-testid={
                                    DATA_TESTIDS.iAlreadyHaveALoginLink
                                }
                            >
                                {t('userSignUpStepper.steps.signUpForm.iAlreadyHaveALoginLink')}
                            </MidmarkLink>
                            <MidmarkButton
                                type="submit"
                                isSubmitting={props.isSubmitting}
                                data-testid={DATA_TESTIDS.signupButton}
                                disabled={!iAmNotARobot}
                            >
                                {t('userSignUpStepper.steps.signUpForm.signUpButton')}
                            </MidmarkButton>
                        </>
                    }
                >
                    <MidmarkFormikTextField
                        label={
                            <span>
                                {t('userSignUpStepper.steps.signUpForm.firstNameLabel')}{' '}
                                <span style={{ color: 'red' }}>*</span>
                            </span>
                        }
                        autoComplete="firstname"
                        data-testid={DATA_TESTIDS.firstNameTextField}
                        placeholder={t('userSignUpStepper.steps.signUpForm.firstNamePlaceholder')}
                        inputProps={{
                            'data-testid': DATA_TESTIDS.firstNameTextFieldInput,
                        }}
                        formikProps={props}
                        name="firstName"
                        fullWidth={true}
                    />
                    <MidmarkFormikTextField
                        label={
                            <span>
                                {t('userSignUpStepper.steps.signUpForm.lastNameLabel')}{' '}
                                <span style={{ color: 'red' }}>*</span>
                            </span>
                        }
                        autoComplete="lastname"
                        data-testid={DATA_TESTIDS.lastNameTextField}
                        placeholder={t('userSignUpStepper.steps.signUpForm.lastNamePlaceholder')}
                        inputProps={{
                            'data-testid': DATA_TESTIDS.lastNameTextFieldInput,
                        }}
                        formikProps={props}
                        name="lastName"
                        fullWidth={true}
                    />
                    <MidmarkFormikTextField
                        label={
                            <span>
                                {t('userSignUpStepper.steps.signUpForm.businessEmailAddressLabel')}{' '}
                                <span style={{ color: 'red' }}>*</span>
                            </span>
                        }
                        autoComplete="email"
                        data-testid={DATA_TESTIDS.emailTextField}
                        placeholder={t('userSignUpStepper.steps.signUpForm.emailPlaceholder')}
                        inputProps={{
                            'data-testid': DATA_TESTIDS.emailTextFieldInput,
                        }}
                        formikProps={props}
                        name="email"
                        fullWidth={true}
                    />
                    <MidmarkFormikTextField
                        label={
                            <span>
                                {t('userSignUpStepper.steps.signUpForm.passwordLabel')} <span style={{ color: 'red' }}>*</span>
                            </span>
                        }
                        autoComplete="password"
                        data-testid={DATA_TESTIDS.passwordTextField}
                        placeholder={t('userSignUpStepper.steps.signUpForm.passwordPlaceholder')}
                        type={passwordVisible ? 'text' : 'password'}
                        inputProps={{
                            'data-testid': DATA_TESTIDS.passwordTextFieldInput,
                        }}
                        InputProps={{
                            endAdornment: props.values.password &&
                                props.values.password.length > 0 && (
                                    <IconButton
                                        data-testid={
                                            DATA_TESTIDS.togglePasswordVisibilityButton
                                        }
                                        sx={{ p: 0 }}
                                        onClick={togglePasswordVisibility}
                                    >
                                        {passwordVisible ? (
                                            <VisibilityIcon />
                                        ) : (
                                            <VisibilityOffIcon />
                                        )}
                                    </IconButton>
                                ),
                        }}
                        formikProps={props}
                        name="password"
                        fullWidth={true}
                    />
                    <MidmarkFormikTextField
                        label={
                            <span>
                                {t('userSignUpStepper.steps.signUpForm.confirmPasswordLabel')}{' '}
                                <span style={{ color: 'red' }}>*</span>
                            </span>
                        }
                        autoComplete="password"
                        data-testid={DATA_TESTIDS.confirmPasswordTextField}
                        placeholder={t('userSignUpStepper.steps.signUpForm.confirmPasswordPlaceholder')}
                        type={confirmPasswordVisible ? 'text' : 'password'}
                        inputProps={{
                            'data-testid':
                                DATA_TESTIDS.confirmPasswordTextFieldInput,
                        }}
                        InputProps={{
                            endAdornment: props.values.confirmPassword &&
                                props.values.confirmPassword.length > 0 && (
                                    <IconButton
                                        sx={{ p: 0 }}
                                        data-testid={
                                            DATA_TESTIDS.toggleConfirmPasswordVisibilityButton
                                        }
                                        onClick={
                                            toggleConfirmPasswordVisibility
                                        }
                                    >
                                        {confirmPasswordVisible ? (
                                            <VisibilityIcon />
                                        ) : (
                                            <VisibilityOffIcon />
                                        )}
                                    </IconButton>
                                ),
                        }}
                        formikProps={props}
                        name="confirmPassword"
                        fullWidth={true}
                    />
                    {showNotification && (
                        <MidmarkInlineNotification
                            sx={{ mt: 1 }}
                            severity={
                                notificationStatus === 'error'
                                    ? 'error'
                                    : 'info'
                            }
                            icon={<ErrorIcon />}
                            data-testid={DATA_TESTIDS.errorNotification}
                            forceOpen={false}
                        >
                            {notificationText}
                        </MidmarkInlineNotification>
                    )}
                    <Recaptcha
                        style={{
                            maxWidth: 'fit-content',
                            alignSelf: 'center',
                            marginTop: 20,
                        }}
                        iAmNotARobot={iAmNotARobot}
                        updateIAmNotARobot={setIAmNotARobot}
                        showPleaseCompleteRecaptchaError={
                            showRecaptchaRequiredMessage
                        }
                        verificationService={
                            VerificationServiceType.UserRegistrationService
                        }
                    />
                </FormLayout>
            )}
        </Formik>
    )
}

export const DATA_TESTIDS = {
    firstNameTextField: 'first-name-text-field',
    firstNameTextFieldInput: 'first-name-text-field-input',
    lastNameTextField: 'last-name-text-field',
    lastNameTextFieldInput: 'last-name-text-field-input',
    emailTextField: 'email-text-field',
    emailTextFieldInput: 'email-text-field-input',
    passwordTextField: 'password-text-field',
    passwordTextFieldInput: 'password-text-field-input',
    confirmPasswordTextField: 'confirm-password-text-field',
    confirmPasswordTextFieldInput: 'confirm-password-text-field-input',
    signupButton: 'sign-up-button',
    iAlreadyHaveALoginLink: 'already-have-a-login-link',
    togglePasswordVisibilityButton: 'toggle-password-visibility-button',
    toggleConfirmPasswordVisibilityButton:
        'toggle-confirm-password-visibility-button',
    invalidPasswordNotification: 'invalid-password-notification',
    errorNotification: 'error-user-notification',
    infoNotification: 'info-user-notification',
}

export interface UserRegistrationFormData {
    email: string
    firstName: string
    lastName: string
    password: string
    confirmPassword: string
}
