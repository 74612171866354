import Button, { ButtonProps } from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'

type ButtonWithProgressProps = {
    isSubmitting?: boolean
} & ButtonProps

const ButtonWithProgress = ({
    isSubmitting,
    children,
    ...newProps
}: ButtonWithProgressProps) => {
    newProps.disabled = newProps.disabled || isSubmitting

    return (
        <Box sx={{ position: 'relative' }}>
            <Button {...newProps}>{children}</Button>
            {isSubmitting && (
                <CircularProgress
                    size={24}
                    sx={(theme) => ({
                        color: theme.palette.primary.main,
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: -12,
                        marginLeft: -12,
                    })}
                />
            )}
        </Box>
    )
}

ButtonWithProgress.propTypes = {
    isSubmitting: PropTypes.bool,
}

export default ButtonWithProgress
