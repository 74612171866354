import { createContext, useContext } from 'react'
import { useMutation, UseMutationOptions } from 'react-query'

export const UserVerificationServiceContext =
    createContext<UserVerificationServiceImplementation | null>(null)
UserVerificationServiceContext.displayName = 'UserVerificationServiceContext'

const ERROR_TEXT =
    'UserVerificationServiceContext must be used within an UserVerificationServiceContext.Provider component'

const useUserVerificationContext = () => {
    const context = useContext(UserVerificationServiceContext)
    if (context == null) {
        throw new Error(ERROR_TEXT)
    }
    return context
}

export const UserVerificationService = {
    useSendVerificationEmailMutation(
        mutationOptions?: Omit<
            UseMutationOptions<
                SendVerificationEmailResponse,
                unknown,
                {
                    emailAddress: string
                    isNewUserRegistration: boolean
                },
                unknown
            >,
            'mutationFn'
        >,
    ) {
        const context = useUserVerificationContext()
        return useMutation({
            mutationFn: ({ emailAddress, isNewUserRegistration }) =>
                context.sendVerificationEmail(
                    emailAddress,
                    isNewUserRegistration,
                ),
            ...mutationOptions,
        })
    },

    useVerifyEmailVerificationCodeMutation(
        mutationOptions?: Omit<
            UseMutationOptions<
                VerifyEmailVerificationCodeResponse,
                unknown,
                {
                    id: string
                    code: string
                    email: string
                },
                unknown
            >,
            'mutationFn'
        >,
    ) {
        const context = useUserVerificationContext()
        return useMutation({
            mutationFn: ({ id, code, email }) =>
                context.verifyEmailVerificationCode(id, code, email),
            ...mutationOptions,
        })
    },

    useVerifiyReCaptchaResponseMutation(
        mutationOptions?: Omit<
            UseMutationOptions<
                VerifyReCaptchaResponse,
                unknown,
                {
                    response: string
                },
                unknown
            >,
            'mutationFn'
        >,
    ) {
        const context = useUserVerificationContext()
        return useMutation({
            mutationFn: ({ response }) =>
                context.verifiyReCaptchaResponse(response),
            ...mutationOptions,
        })
    },

    useResetUserPasswordMutation(
        mutationOptions?: Omit<
            UseMutationOptions<
                ResetUserPasswordResponse,
                unknown,
                {
                    verificationId: string
                    emailAddress: string
                    accessCode: string
                    password: string
                },
                unknown
            >,
            'mutationFn'
        >,
    ) {
        const context = useUserVerificationContext()
        return useMutation({
            mutationFn: ({
                verificationId,
                emailAddress,
                accessCode,
                password,
            }) =>
                context.resetUserPassword(
                    verificationId,
                    emailAddress,
                    accessCode,
                    password,
                ),
            ...mutationOptions,
        })
    },
}

export interface UserVerificationServiceImplementation {
    sendVerificationEmail(
        emailAddress: string,
        isNewUserRegistration: boolean,
    ): Promise<SendVerificationEmailResponse>

    verifyEmailVerificationCode(
        id: string,
        code: string,
        email: string,
    ): Promise<VerifyEmailVerificationCodeResponse>

    verifiyReCaptchaResponse(response: string): Promise<VerifyReCaptchaResponse>

    resetUserPassword(
        verificationId: string,
        emailAddress: string,
        accessCode: string,
        password: string,
    ): Promise<ResetUserPasswordResponse>
}

export type SendVerificationEmailResponse = { identificationGuid: string }
export type VerifyEmailVerificationCodeResponse = { isValid: boolean }
export type VerifyReCaptchaResponse = { isValid: boolean }
export type ResetUserPasswordResponse = { success: boolean }
