import { Hidden, AppBar, Toolbar, Box, styled } from '@mui/material'

import logoBlack from '@/img/small-logo-black.svg'

const StyledImage = styled('img')(({ theme }) => ({
    minWidth: '225px',
    maxWidth: '225px',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(5),
}))

export interface MidmarkScreenButtonsProps {
    doNotDisplayLogoOnMobile?: boolean
    doNotFixToBottomOnMobile?: boolean
    children?: React.ReactNode
}

const MidmarkScreenButtons = ({
    doNotDisplayLogoOnMobile,
    doNotFixToBottomOnMobile,
    children,
}: MidmarkScreenButtonsProps) => {
    return (
        <Box
            sx={{
                width: '100%',
            }}
        >
            {doNotFixToBottomOnMobile ? (
                <>
                    {doNotDisplayLogoOnMobile || (
                        <StyledImage src={logoBlack} alt="" />
                    )}
                    {children}
                </>
            ) : (
                <>
                    <Hidden only={['xs']}>{children}</Hidden>
                    <Hidden only={['xl', 'lg', 'md', 'sm']}>
                        {doNotDisplayLogoOnMobile || (
                            <StyledImage src={logoBlack} alt="" />
                        )}
                        <AppBar
                            position="fixed"
                            sx={{
                                backgroundColor: 'white',
                                top: 'auto',
                                bottom: '0',
                            }}
                        >
                            <Toolbar>{children}</Toolbar>
                        </AppBar>
                    </Hidden>
                </>
            )}
        </Box>
    )
}

export default MidmarkScreenButtons
