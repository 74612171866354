import { Formik } from 'formik'
import * as Yup from 'yup'

import { AppEmailVerification } from '@/App'
import { Navigate } from 'react-router'
import { FormLayout } from '@/components/Shared/FormLayout'
import {
    MidmarkButton,
    MidmarkFormikTextField,
    MidmarkInlineNotification,
    VisibilityIcon,
    VisibilityOffIcon,
} from '@midmark-enterprise/shared-components'
import IconButton from '@mui/material/IconButton'
import { UserVerificationService } from '@/services/UserVerificationService/UserVerificationService'
import { useCallback, useState } from 'react'
import { useSimplePager } from '@/components/Shared/SimplePager'
import { useTranslation } from 'react-i18next'

const oneSpecialCharacterReg = new RegExp(
    /^(?=.*[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]).{1,}$/,
)
const oneLetterReg = new RegExp(/[a-z,A-Z]/)

type CreateNewPasswordFormProps = {
    emailVerification: AppEmailVerification
}

export default function CreateNewPasswordForm({
    emailVerification,
}: CreateNewPasswordFormProps) {
    const { t } = useTranslation()
    const simplePager = useSimplePager()
    const [passwordVisible, setPasswordVisible] = useState(false)
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)

    const YUP_SCHEMA = Yup.object().shape({
        newPassword: Yup.string()
            .required(t('password.createNewPasswordForm.passwordRequired'))
            .min(10, t('password.createNewPasswordForm.passwordMinLength'))
            .matches(
                /\d/,
                t('password.createNewPasswordForm.passwordOneNumber'),
            )
            .matches(
                oneLetterReg,
                t('password.createNewPasswordForm.passwordOneLetter'),
            )
            .matches(
                oneSpecialCharacterReg,
                t('password.createNewPasswordForm.passwordSpecialChar'),
            )
            .oneOf(
                [Yup.ref('confirmPassword')],
                t('password.createNewPasswordForm.passwordsMustMatch'),
            ),
        confirmPassword: Yup.string().required(
            t('password.createNewPasswordForm.confirmPasswordRequired'),
        ),
    })


    const togglePasswordVisibility = useCallback(() => {
        setPasswordVisible(!passwordVisible)
    }, [passwordVisible])
    const toggleConfirmPasswordVisibility = useCallback(() => {
        setConfirmPasswordVisible(!confirmPasswordVisible)
    }, [confirmPasswordVisible])
    const resetUserPasswordMutation =
        UserVerificationService.useResetUserPasswordMutation()

    const handleSubmit = (
        values: { newPassword: string },
        action: {
            setSubmitting: (submitting: boolean) => void
            setFieldError: (field: string, errorMessage: string) => void
        },
    ) => {
        if (!emailVerification.id) {
            throw new Error('Missing email verification id')
        }
        if (!emailVerification.code) {
            throw new Error('Missing email verification code')
        }
        if (!emailVerification.emailAddress) {
            throw new Error('Missing email address')
        }

        resetUserPasswordMutation
            .mutateAsync({
                verificationId: emailVerification.id,
                emailAddress: emailVerification.emailAddress,
                accessCode: emailVerification.code,
                password: values.newPassword,
            })
            .then((response) => {
                action.setSubmitting(false)
                if (!emailVerification.emailAddress) {
                    throw new Error('Missing email address')
                }
                if (response.success) {
                    simplePager.nextPage()
                } else {
                    action.setFieldError(
                        'newPassword',
                        t('password.createNewPasswordForm.errorOccurred'),
                    )
                    console.log(response)
                }
            })
            .catch((error) => {
                action.setFieldError(
                    'confirmPassword',
                    t('password.createNewPasswordForm.errorOccurred'),
                )
                console.log(error)
            })
    }

    if (!emailVerification.id) {
        return <Navigate to="/password/reset" />
    }

    return (
        <Formik
            initialValues={{
                newPassword: '',
                confirmPassword: '',
            }}
            validationSchema={YUP_SCHEMA}
            onSubmit={handleSubmit}
        >
            {(props) => (
                <FormLayout
                    title={t('password.createNewPasswordForm.title')}
                    buttons={
                        <MidmarkButton
                            type="submit"
                            isSubmitting={props.isSubmitting}
                            data-testid={DATA_TESTIDS.submitButton}
                        >
                            {t('password.createNewPasswordForm.submitButton')}
                        </MidmarkButton>
                    }
                >
                    <MidmarkFormikTextField
                        label={t('password.createNewPasswordForm.passwordLabel')}
                        placeholder={t('password.createNewPasswordForm.passwordPlaceholder')}
                        autoComplete="password"
                        formikProps={props}
                        required
                        name="newPassword"
                        data-testid={DATA_TESTIDS.newPasswordTextField}
                        type={passwordVisible ? 'text' : 'password'}
                        inputProps={{
                            'data-testid': DATA_TESTIDS.newPasswordInput,
                        }}
                        InputProps={{
                            endAdornment: props.values.newPassword &&
                                props.values.newPassword.length > 0 && (
                                    <IconButton
                                        data-testid={
                                            DATA_TESTIDS.togglePasswordVisibilityButton
                                        }
                                        sx={{ p: 0 }}
                                        onClick={togglePasswordVisibility}
                                    >
                                        {passwordVisible ? (
                                            <VisibilityIcon />
                                        ) : (
                                            <VisibilityOffIcon />
                                        )}
                                    </IconButton>
                                ),
                        }}
                    />
                    <MidmarkFormikTextField
                        label={t('password.createNewPasswordForm.confirmPasswordLabel')}
                        placeholder={t('password.createNewPasswordForm.confirmPasswordPlaceholder')}
                        autoComplete="password"
                        formikProps={props}
                        required
                        name="confirmPassword"
                        inputProps={{
                            'data-testid': DATA_TESTIDS.confirmPasswordInput,
                        }}
                        data-testid={DATA_TESTIDS.confirmPasswordTextField}
                        type={confirmPasswordVisible ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: props.values.confirmPassword &&
                                props.values.confirmPassword.length > 0 && (
                                    <IconButton
                                        sx={{ p: 0 }}
                                        data-testid={
                                            DATA_TESTIDS.toggleConfirmPasswordVisibilityButton
                                        }
                                        onClick={
                                            toggleConfirmPasswordVisibility
                                        }
                                    >
                                        {confirmPasswordVisible ? (
                                            <VisibilityIcon />
                                        ) : (
                                            <VisibilityOffIcon />
                                        )}
                                    </IconButton>
                                ),
                        }}
                    />

                    <MidmarkInlineNotification
                        severity="info"
                        forceOpen={true}
                        data-testid={DATA_TESTIDS.invalidPasswordNotification}
                    >
                        {t('password.createNewPasswordForm.invalidPasswordNotification')}
                    </MidmarkInlineNotification>
                </FormLayout>
            )}
        </Formik>
    )
}

export const DATA_TESTIDS = {
    confirmPasswordTextField: 'confirm-password-text-field',
    confirmPasswordInput: 'confirm-password-input',
    newPasswordTextField: 'new-password-text-field',
    newPasswordInput: 'new-password-input',
    submitButton: 'submit-button',
    togglePasswordVisibilityButton: 'toggle-password-visibility-button',
    toggleConfirmPasswordVisibilityButton:
        'toggle-confirm-password-visibility-button',
    invalidPasswordNotification: 'invalid-password-notification',
}
