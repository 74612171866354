import { FormLayout } from '@/components/Shared/FormLayout'
import {
    MidmarkButton,
    MidmarkFormikTextField,
    MidmarkInlineNotification,
    MidmarkLink,
    UserRegistrationService,
} from '@midmark-enterprise/shared-components'
import { Formik, FormikProps } from 'formik'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import {
    UserRegistrationDataContext,
    UserSignupWizardPages,
} from '../user-signup-modal.component'
import { useTranslation } from 'react-i18next'

type EmailVerificationStepProps = {
    handleBackButton: (page: UserSignupWizardPages) => void
    handleForwardButton: (id: string, code: string) => void
}

export default function EmailVerificationStep(
    props: EmailVerificationStepProps,
): JSX.Element {
    const userRegistrationData = useContext(UserRegistrationDataContext)
    const { t } = useTranslation()
    const [showNotification, setShowNotification] = useState(false)
    const [notificationErrorStatus, setNotificationErrorStatus] =
        useState(false)
    const [notificationText, setNotificationText] = useState('')
    const [formFilled, setFormFilled] = useState(false)
    const [isSendingCode, setIsSendingCode] = useState(false)
    const [formEmailVerification, setFormEmailVerification] = useState({
        id: '',
        emailAddress: userRegistrationData?.userRegistrationData?.email,
        verificationCode: '',
    })
    const verifyEmailVerificationCodeMutation =
        UserRegistrationService.useVerifyEmailVerificationCodeMutation()
    const sendVerificationEmailMutation =
        UserRegistrationService.useSendVerificationEmailMutation()

    const obfuscatedEmailAddress = useMemo(
        () =>
            formEmailVerification.emailAddress?.replace(
                /^(.{2})[^@]+/,
                '$1***',
            ),
        [formEmailVerification.emailAddress],
    )

    const sendNewVerificationCode = useCallback(
        (props?: FormikProps<any>, showSuccessMessage?: boolean) => {
            if (isSendingCode) {
                return
            }

            setShowNotification(false)
            setIsSendingCode(true)

            var userData = userRegistrationData?.userRegistrationData

            sendVerificationEmailMutation
                .mutateAsync({
                    emailAddress: userData?.email ?? '',
                    isNewUserRegistration: true,
                    fullName: userData?.firstName + ' ' + userData?.lastName,
                })
                .then((data) => {
                    sessionStorage.setItem(
                        'identificationGuid',
                        data.identificationGuid,
                    )

                    setFormEmailVerification({
                        ...formEmailVerification,
                        id: data.identificationGuid,
                    })
                    setIsSendingCode(false)

                    if (showSuccessMessage) {
                        setShowNotification(true)
                        setNotificationErrorStatus(false)
                        setNotificationText(t('userSignUpStepper.steps.emailVerificationStep.codeSentSuccess'))
                    }

                    if (props) {
                        props.resetForm()
                    }
                })
                .catch((error) => {
                    console.log(error)
                    setIsSendingCode(false)
                    setShowNotification(true)
                    setNotificationErrorStatus(true)
                    setNotificationText(t('userSignUpStepper.steps.emailVerificationStep.codeSentError'))
                })
        },
        [
            isSendingCode,
            formEmailVerification,
            sendVerificationEmailMutation,
            userRegistrationData,
        ],
    )

    const validate = useCallback((values: { verifyEmail: string }) => {
        if (values.verifyEmail.length === 6) {
            setFormFilled(true)
        }
    }, [])

    const handleSubmit = useCallback(
        (
            values: { verifyEmail: string },
            action: {
                setFieldError: (fieldName: string, errorMessage: string) => void
                setSubmitting: (isSubmitting: boolean) => void
            },
        ) => {
            const code = values.verifyEmail.toUpperCase()

            if (!formEmailVerification.id) {
                throw new Error('Missing formEmailVerification.id')
            }

            if (!formEmailVerification.emailAddress) {
                throw new Error('Missing formEmailVerification.emailAddress')
            }

            verifyEmailVerificationCodeMutation
                .mutateAsync({
                    id: formEmailVerification.id,
                    code: code,
                    email: formEmailVerification.emailAddress,
                })
                .then((response) => {
                    setFormFilled(false)

                    action.setSubmitting(false)
                    if (response.isValid) {
                        props.handleForwardButton(
                            formEmailVerification.id,
                            code,
                        )
                    } else {
                        action.setFieldError('verifyEmail', '')
                        setShowNotification(true)
                        setNotificationErrorStatus(true)
                        setNotificationText(
                            t('userSignUpStepper.steps.emailVerificationStep.codeErrorResend'),
                        )
                    }
                })
                .catch((error) => console.log(error))
        },
        [
            formEmailVerification.emailAddress,
            formEmailVerification.id,
            verifyEmailVerificationCodeMutation,
        ],
    )

    const submitForm = useCallback((props: FormikProps<any>) => {
        if (!props.isSubmitting) {
            props.submitForm()
        }
    }, [])

    const handleBackButton = (): void => {
        props.handleBackButton(UserSignupWizardPages.profileInformation)
    }

    useEffect(() => {
        if (!userRegistrationData?.initialEmailVerificationCodeSent) {
            userRegistrationData?.setInitialEmailVerificationCodeSent(true)
            sendNewVerificationCode(undefined, false)
        }
    }, [])
    const descriptionText = `${t('userSignUpStepper.steps.emailVerificationStep.descriptionPart1')} ${obfuscatedEmailAddress} ${t('userSignUpStepper.steps.emailVerificationStep.descriptionPart2')}`
    return (
        <Formik
            initialValues={{
                verifyEmail: '',
            }}
            validateOnChange={false}
            validateOnBlur={true}
            validate={validate}
            onSubmit={handleSubmit}
        >
            {(props) => (
                <>
                    <FormLayout
                        title={t('userSignUpStepper.steps.emailVerificationStep.title')}
                        description={descriptionText}
                        buttons={
                            <>
                                <MidmarkButton
                                    onClick={handleBackButton}
                                    data-testid={DATA_TESTIDS.backButton}
                                    color="secondary"
                                >
                                    {t('userSignUpStepper.steps.emailVerificationStep.backButton')}
                                </MidmarkButton>
                                <MidmarkButton
                                    isSubmitting={props.isSubmitting}
                                    data-testid={DATA_TESTIDS.verifyButton}
                                    type="submit"
                                >
                                    {t('userSignUpStepper.steps.emailVerificationStep.verifyButton')}
                                </MidmarkButton>
                            </>
                        }
                    >
                        <MidmarkFormikTextField
                            label={t('userSignUpStepper.steps.emailVerificationStep.verificationCodeLabel')}
                            placeholder={t('userSignUpStepper.steps.emailVerificationStep.verificationCodePlaceholder')}
                            formikProps={props}
                            autoComplete="off"
                            name="verifyEmail"
                            required={true}
                            data-testid={DATA_TESTIDS.verificationCodeTextField}
                            sx={{
                                '& input': {
                                    textTransform: 'uppercase',
                                    '&::placeholder': {
                                        textTransform: 'none',
                                    },
                                },
                            }}
                        />

                        <MidmarkLink
                            component={'button'}
                            type="button"
                            onClick={() => sendNewVerificationCode(props, true)}
                            data-testid={DATA_TESTIDS.resendCodeButton}
                        >
                            Resend code
                        </MidmarkLink>

                        {showNotification && (
                            <MidmarkInlineNotification
                                sx={{ mt: 1 }}
                                severity={
                                    notificationErrorStatus
                                        ? 'error'
                                        : 'success'
                                }
                                data-testid={
                                    notificationErrorStatus
                                        ? DATA_TESTIDS.errorNotification
                                        : DATA_TESTIDS.successNotification
                                }
                                forceOpen={true}
                            >
                                {notificationText}
                            </MidmarkInlineNotification>
                        )}
                    </FormLayout>
                    {formFilled && submitForm(props)}
                </>
            )}
        </Formik>
    )
}

export const DATA_TESTIDS = {
    verifyButton: 'verify-button',
    backButton: 'back-button',
    verificationCodeTextField: 'verification-code-text-field',
    formikForm: 'formik-form',
    resendCodeButton: 'resend-code-button',
    successNotification: 'success-user-notification',
    errorNotification: 'error-user-notification',
}
