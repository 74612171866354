import E2ETestingTools from '@/utility/E2ETestingTools'
import {
    ResetUserPasswordResponse,
    SendVerificationEmailResponse,
    UserVerificationServiceImplementation,
    VerifyEmailVerificationCodeResponse,
    VerifyReCaptchaResponse,
} from '../UserVerificationService'

const API_VERSION = 0

export class UserVerificationServiceApi
    implements UserVerificationServiceImplementation
{
    sendVerificationEmail(
        emailAddress: string,
        isNewUserRegistration: boolean = false,
    ): Promise<SendVerificationEmailResponse> {
        const sendEmails = E2ETestingTools.active()

        return fetch(
            `${import.meta.env.VITE_APIM_URL}${
                import.meta.env.VITE_USER_ROUTE
            }/api/email-verification?api-version=${API_VERSION}`,
            {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                }),
                body: JSON.stringify({
                    emailAddress: emailAddress,
                    sendEmails: !sendEmails,
                    isNewUserRegistration: isNewUserRegistration,
                }),
            },
        ).then((response) => {
            if (E2ETestingTools.active() && response.ok) {
                response
                    .clone()
                    .json()
                    .then((data) =>
                        E2ETestingTools.setEmailVerification(
                            JSON.stringify(data),
                        ),
                    )
            }
            return response.json()
        })
    }

    async verifyEmailVerificationCode(
        id: string,
        code: string,
        email: string,
    ): Promise<VerifyEmailVerificationCodeResponse> {
        const response = await fetch(
            `${import.meta.env.VITE_APIM_URL}${
                import.meta.env.VITE_USER_ROUTE
            }/api/email-verification/${id}/verify/${code}?api-version=${API_VERSION}&email=${encodeURIComponent(
                email,
            )}`,
            {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                }),
            },
        )

        return { isValid: response.status === 200 }
    }
    async verifiyReCaptchaResponse(
        response: string,
    ): Promise<VerifyReCaptchaResponse> {
        const recaptchaResult = await fetch(
            `${import.meta.env.VITE_APIM_URL}${
                import.meta.env.VITE_USER_ROUTE
            }/api${
                import.meta.env.VITE_USER_ROUTE
            }/recaptcha?api-version=${API_VERSION}&response=${response}`,
            {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                }),
                body: JSON.stringify({
                    response: response,
                }),
            },
        )

        return await recaptchaResult.json()
    }
    async resetUserPassword(
        verificationId: string,
        emailAddress: string,
        accessCode: string,
        password: string,
    ): Promise<ResetUserPasswordResponse> {
        const response = await fetch(
            `${import.meta.env.VITE_APIM_URL}${
                import.meta.env.VITE_USER_ROUTE
            }/api/email-verification/${verificationId}/password-reset?api-version=${API_VERSION}`,
            {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                }),
                body: JSON.stringify({
                    accessCode: accessCode,
                    emailAddress: emailAddress,
                    password: password,
                }),
            },
        )

        return { success: response.status === 200 }
    }
}
