import { useCallback, useMemo, useState } from 'react'

import { Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'

import Recaptcha from '@/components/Shared/Recaptcha/Recaptcha'

import { useDocumentTitle } from '@/utility/useDocumentTitle'
import {
    MidmarkFormikTextField,
    MidmarkButton,
    MidmarkLink,
    VerificationServiceType,
} from '@midmark-enterprise/shared-components'
import { FormLayout } from '@/components/Shared/FormLayout'
import { UserVerificationService } from '@/services/UserVerificationService/UserVerificationService'
import { useSimplePager } from '@/components/Shared/SimplePager'
import { useTranslation } from 'react-i18next'

type PasswordResetFormProps = {
    redirectUri: string
    email: string
    updateEmailVerification: (
        identificationGuid: string,
        emailAddress: string,
    ) => void
}

export function buildSchema(t: (key: string) => string) {
    return Yup.object().shape({
        emailAddress: Yup.string()
            .email(t('password.passwordResetForm.invalidEmail'))
            .required(t('password.passwordResetForm.emailRequired'))
            .matches(
                /^(?!.*@midmark.com).*$/,
                t('password.passwordResetForm.emailRestriction'),
            ),
    })
}

export default function PasswordResetForm({
    redirectUri,
    updateEmailVerification,
    email,
}: PasswordResetFormProps) {
    const { t } = useTranslation()
    const simplePager = useSimplePager()
    const sendVerificationEmailMutation =
        UserVerificationService.useSendVerificationEmailMutation()
    const [iAmNotARobot, setIAmNotARobot] = useState(false)
    const [showRecaptchaRequiredMessage, setShowRecaptchaRequiredMessage] =
        useState(false)
    useDocumentTitle(t('password.passwordResetForm.pageTitle'))

    const validationSchema = useMemo(() => {
        return buildSchema(t)
    }, [t])

    const handleSubmit = useCallback(
        (
            values: { emailAddress: string },
            formikHelpers: FormikHelpers<{
                emailAddress: string
            }>,
        ) => {
            if (!iAmNotARobot) {
                setShowRecaptchaRequiredMessage(true)
                formikHelpers.setSubmitting(false)
                return
            }

            return sendVerificationEmailMutation
                .mutateAsync({
                    emailAddress: values.emailAddress,
                    isNewUserRegistration: false,
                })
                .then((data) => {
                    updateEmailVerification(
                        data.identificationGuid,
                        values.emailAddress,
                    )
                    simplePager.nextPage()
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        [
            iAmNotARobot,
            sendVerificationEmailMutation,
            simplePager,
            updateEmailVerification,
        ],
    )

    return (
        <Formik
            initialValues={{
                emailAddress: email,
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {(props) => (
                <FormLayout
                    title={t('password.passwordResetForm.title')}
                    description={t('password.passwordResetForm.description')}
                    buttons={
                        <>
                            {redirectUri && redirectUri.length > 0 && (
                                <MidmarkLink
                                    variant="body1"
                                    component="a"
                                    href={redirectUri}
                                    data-testid={
                                        DATA_TESTIDS.iKnowMyPasswordLink
                                    }
                                >
                                    {t('password.passwordResetForm.iKnowMyPasswordLink')}
                                </MidmarkLink>
                            )}
                            <MidmarkButton
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={
                                    iAmNotARobot
                                        ? 'recaptcha-successful'
                                        : undefined
                                }
                                data-testid={DATA_TESTIDS.continueButton}
                                isSubmitting={props.isSubmitting}
                            >
                                {t('password.passwordResetForm.continueButton')}
                            </MidmarkButton>
                        </>
                    }
                >
                    <MidmarkFormikTextField
                        label={t('password.passwordResetForm.emailLabel')}
                        autoComplete="email"
                        data-testid={DATA_TESTIDS.emailTextField}
                        placeholder={t('password.passwordResetForm.emailPlaceholder')}
                        inputProps={{
                            'data-testid': DATA_TESTIDS.emailTextFieldInput,
                        }}
                        formikProps={props}
                        name="emailAddress"
                        fullWidth={true}
                    />

                    <Recaptcha
                        style={{
                            maxWidth: 'fit-content',
                            alignSelf: 'center',
                        }}
                        iAmNotARobot={iAmNotARobot}
                        updateIAmNotARobot={setIAmNotARobot}
                        showPleaseCompleteRecaptchaError={
                            showRecaptchaRequiredMessage
                        }
                        data-testid={DATA_TESTIDS.recaptcha}
                        verificationService={
                            VerificationServiceType.UserVerificationService
                        }
                    />
                </FormLayout>
            )}
        </Formik>
    )
}

export const DATA_TESTIDS = {
    continueButton: 'continue-button',
    iKnowMyPasswordLink: 'i-know-my-password-link',
    recaptcha: 'recaptcha',
    signInUsingEmailLink: 'sign-in-using-email-link',
    emailTextField: 'email-text-field',
    emailTextFieldInput: 'email-text-field-input',
}
