import { Box } from '@mui/material'
import { AnimatePresence, motion, Variants } from 'framer-motion'
import React, { createContext, useContext, useMemo, useState } from 'react'

type SimplePagerProps = {
    children: React.ReactNode | React.ReactNode[]
}

export const SimplePagerContext = createContext<{
    nextPage: () => void
    previousPage: () => void
} | null>(null)
SimplePagerContext.displayName = 'SimplePagerContext'

const ERROR_TEXT =
    'useSimplePager can only be used within a SimplePager component'

export const useSimplePager = () => {
    const context = useContext(SimplePagerContext)
    if (context == null) {
        throw new Error(ERROR_TEXT)
    }
    return context
}

type Direction = 'forward' | 'backward'

export const variants = {
    enter: (direction: Direction) => {
        return {
            x: direction === 'forward' ? 450 : -450,
            opacity: 0,
        }
    },
    center: {
        zIndex: 1,
        x: 0,
        opacity: 1,
    },
    exit: (direction: Direction) => {
        return {
            zIndex: 0,
            x: direction === 'forward' ? -450 : 450,
            opacity: 0,
            position: 'absolute',
        }
    },
} satisfies Variants

/**
 * Each child in this pager is considered it's own 'page'
 * The pager will start out rendering the first child
 * Move to the next or previous page using the `useSimplePager` hook
 */
export function SimplePager({ children }: SimplePagerProps) {
    const [navigationDirection, setNavigationDirection] =
        useState<Direction>('forward')
    const [currentPage, setCurrentPage] = useState(0)
    const pages = useMemo(() => React.Children.toArray(children), [children])
    const navigator: {
        nextPage: () => void
        previousPage: () => void
    } = useMemo(
        () => ({
            nextPage() {
                setNavigationDirection('forward')
                setCurrentPage((page) =>
                    page < pages.length ? page + 1 : page,
                )
            },
            previousPage() {
                setNavigationDirection('backward')
                setCurrentPage((page) => (page > 0 ? page - 1 : page))
            },
        }),
        [pages],
    )

    return (
        <SimplePagerContext.Provider value={navigator}>
            <Box
                sx={{
                    position: 'relative',
                    overflowX: 'hidden',
                    overflowY: 'hidden',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <AnimatePresence initial={false} custom={navigationDirection}>
                    <motion.div
                        custom={navigationDirection}
                        variants={variants}
                        initial="enter"
                        exit="exit"
                        animate="center"
                        transition={{ bounce: 0, duration: 0.5 }}
                        key={currentPage}
                        style={{ display: 'flex', flexDirection: 'column' }}
                    >
                        {pages[currentPage]}
                    </motion.div>
                </AnimatePresence>
            </Box>
        </SimplePagerContext.Provider>
    )
}
