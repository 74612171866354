import React, { useRef, useState } from 'react'

import Typography from '@mui/material/Typography'

import ReCAPTCHA from 'react-google-recaptcha'

import { useMediaQuery } from '@mui/material'
import { UserVerificationService } from '@/services/UserVerificationService/UserVerificationService'
import {
    UserRegistrationService,
    VerificationServiceType,
} from '@midmark-enterprise/shared-components'
import { useTranslation } from 'react-i18next'

const recaptchaSiteKey = import.meta.env.VITE_RECAPTCHA_SITEKEY || ''

export interface RecaptchaProps {
    updateIAmNotARobot: (value: boolean) => void
    showPleaseCompleteRecaptchaError: boolean
    iAmNotARobot: boolean
    style: React.CSSProperties
    verificationService: string
}

const Recaptcha = ({
    updateIAmNotARobot,
    iAmNotARobot,
    showPleaseCompleteRecaptchaError,
    style,
    verificationService,
}: RecaptchaProps) => {
    const verifiyReCaptchaResponseMutation =
        UserVerificationService.useVerifiyReCaptchaResponseMutation()

    const verifyRegistrationReCaptchaResponseMutation =
        UserRegistrationService.useVerifiyReCaptchaResponseMutation()

    const [recaptchaSubmitted, setRecaptchaSubmitted] = useState(false)
    const recaptchaRef = useRef<ReCAPTCHA>(null)
    const { t } = useTranslation()
    const onCaptchaChange = (value: string | null) => {
        setRecaptchaSubmitted(false)

        if (!value) {
            updateIAmNotARobot(false)
            return
        }

        if (
            verificationService ===
            VerificationServiceType.UserVerificationService
        ) {
            useVerificationService(value)
        } else if (
            verificationService ===
            VerificationServiceType.UserRegistrationService
        ) {
            useRegistrationService(value)
        }
    }

    const useVerificationService = (value: string) => {
        verifiyReCaptchaResponseMutation
            .mutateAsync({ response: value })
            .then((data) => {
                setRecaptchaSubmitted(true)
                if (data.isValid) {
                    updateIAmNotARobot(true)
                    return
                }

                updateIAmNotARobot(false)
                recaptchaRef.current?.reset()
            })
            .catch((error) => {
                setRecaptchaSubmitted(true)
                updateIAmNotARobot(false)
                recaptchaRef.current?.reset()
                console.log(error)
            })
    }

    const useRegistrationService = (value: string) => {
        verifyRegistrationReCaptchaResponseMutation
            .mutateAsync({ response: value })
            .then((data) => {
                setRecaptchaSubmitted(true)
                if (data.isValid) {
                    updateIAmNotARobot(true)
                    return
                }

                updateIAmNotARobot(false)
                recaptchaRef.current?.reset()
            })
            .catch((error) => {
                setRecaptchaSubmitted(true)
                updateIAmNotARobot(false)
                recaptchaRef.current?.reset()
                console.log(error)
            })
    }

    const isExtraSmallScreen = useMediaQuery('(max-width: 350px)', {
        defaultMatches: true,
    })

    return (
        <>
            {/* Separate keys are used here to force React to re-render the component 
                See: https://github.com/dozoisch/react-google-recaptcha/issues/69  */}
            {isExtraSmallScreen ? (
                <ReCAPTCHA
                    key="compact-recaptcha"
                    style={style}
                    ref={recaptchaRef}
                    sitekey={recaptchaSiteKey}
                    onChange={onCaptchaChange}
                    size="compact"
                    theme="light"
                />
            ) : (
                <ReCAPTCHA
                    key="normal-recaptcha"
                    style={style}
                    ref={recaptchaRef}
                    sitekey={recaptchaSiteKey}
                    onChange={onCaptchaChange}
                    size="normal"
                    theme="light"
                />
            )}
            {showPleaseCompleteRecaptchaError && (
                <Typography
                    variant="caption"
                    sx={{ ml: isExtraSmallScreen ? 0 : '32px' }}
                    color="error"
                    data-testid={DATA_TESTIDS.recaptchaIsRequiredError}
                >
                    {t('shared.recaptcha.recaptcha.completeRecaptcha')}
                </Typography>
            )}
            {!iAmNotARobot && recaptchaSubmitted && (
                <Typography
                    color="error"
                    data-testid={DATA_TESTIDS.recaptchaIAmARobotError}
                >
                    {t('shared.recaptcha.recaptcha.verificationFailed')}
                </Typography>
            )}
        </>
    )
}

const DATA_TESTIDS = {
    recaptchaIAmARobotError: 'i-am-a-robot-error-text',
    recaptchaIsRequiredError: 'is-required-error-text',
}

export default Recaptcha
