import { Grid, styled, Typography } from '@mui/material'

import IconWithTitleAndStatus from '@/components/Shared/IconWithTitleAndStatus'
import MidmarkScreenButtons from '@/components/Shared/MidmarkScreenButtons'
import ButtonWithProgress from '@/components/Shared/ButtonWithProgress'

import EnvelopeGraphic from '@/img/icons/Envelope Graphic.svg'
import { AppEmailVerification } from '@/App'
import { useNavigate } from 'react-router'
import { useCallback } from 'react'
import { UserVerificationService } from '@/services/UserVerificationService/UserVerificationService'
import { useTranslation } from 'react-i18next'

const MainGrid = styled(Grid)(({ theme }) => ({
    textAlign: 'left',
    '& > *': {
        marginTop: '0',
        marginBottom: theme.spacing(4),
        maxWidth: '100%',
    },
}))

const LogoGrid = styled(Grid)({
    '& > *:first-child': {
        marginTop: '5px',
    },
})

type EmailVerificationErrorProps = {
    emailVerification: AppEmailVerification
    updateEmailVerification: (
        id: string | null,
        emailAddress: string,
        code?: string | null,
        callback?: () => void,
    ) => void
}

export default function EmailVerificationError({
    emailVerification,
    updateEmailVerification,
}: EmailVerificationErrorProps) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const sendVerificationEmailMutation =
        UserVerificationService.useSendVerificationEmailMutation()

    const sendEmailVerification = useCallback(() => {
        if (!emailVerification.emailAddress) {
            throw new Error('Missing emailVerification.emailAddress')
        }

        sendVerificationEmailMutation
            .mutateAsync({
                emailAddress: emailVerification.emailAddress,
                isNewUserRegistration: false,
            })
            .then((data) => {
                if (!emailVerification.emailAddress) {
                    throw new Error('Missing emailVerification.emailAddress')
                }

                updateEmailVerification(
                    data.identificationGuid,
                    emailVerification.emailAddress,
                    null,
                    () =>
                        navigate('/password/email-verification', {
                            state: {
                                backUrl: '/email-verification-error',
                                forwardUrl: '/password/create',
                            },
                        }),
                )
            })
            .catch((error) => console.log(error))
    }, [
        emailVerification.emailAddress,
        navigate,
        sendVerificationEmailMutation,
        updateEmailVerification,
    ])

    return (
        <Grid container direction="row" alignItems="stretch">
            <MainGrid>
                <LogoGrid container direction="column" alignItems="center">
                    <IconWithTitleAndStatus
                        title={t('emailVerificationForm.emailVerificationError.title')}
                        status="Warn"
                        iconSrc={EnvelopeGraphic}
                    />
                </LogoGrid>
                <Typography variant="body2">
                    {t('emailVerificationForm.emailVerificationError.description')}
                </Typography>
                <ul>
                    <li>
                        <Typography variant="body2">
                            {t('emailVerificationForm.emailVerificationError.reason1')}
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2">
                            {t('emailVerificationForm.emailVerificationError.reason2')}
                        </Typography>
                    </li>
                </ul>
                <Typography variant="body2">
                    {t('emailVerificationForm.emailVerificationError.additionalInfo')}
                </Typography>
                <Typography variant="body2">
                    {t('emailVerificationForm.emailVerificationError.supportInfo')}
                </Typography>
            </MainGrid>
            <Grid
                sx={{
                    width: '100%',
                }}
                item
            >
                <MidmarkScreenButtons
                    doNotFixToBottomOnMobile={true}
                    doNotDisplayLogoOnMobile={true}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-end"
                    >
                        <ButtonWithProgress
                            className="Continue"
                            variant="contained"
                            type="submit"
                            color="primary"
                            data-testid={DATA_TESTIDS.verifyEmailButton}
                            onClick={sendEmailVerification}
                            style={{ marginRight: '-10px' }}
                        >
                            Verify Email
                        </ButtonWithProgress>
                    </Grid>
                </MidmarkScreenButtons>
            </Grid>
        </Grid>
    )
}

const DATA_TESTIDS = {
    verifyEmailButton: 'verify-email-button',
}
