import { Grid, styled } from '@mui/material'

import { MidmarkTypography } from '@midmark-enterprise/shared-components'
import { Form } from 'formik'

const StyledForm = styled(Form)({
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    flexWrap: 'nowrap',
})

export const FormLayout = ({
    children,
    title,
    description,
    buttons,
}: {
    children: React.ReactNode | React.ReactNode[]
    title: string
    description?: string
    buttons?: React.ReactNode
}) => {
    return (
        <StyledForm autoComplete="off" data-testid="form-layout">
            <Grid
                sx={{
                    flex: 1,
                    '& > *:not(:last-child)': {
                        maxWidth: '100%',
                    },
                }}
                container
                wrap="nowrap"
                direction="column"
            >
                <MidmarkTypography
                    sx={{ mb: 2.5 }}
                    variant="h3"
                    data-testid={DATA_TESTIDS.formTitleTextField}
                >
                    {title}
                </MidmarkTypography>

                {description && (
                    <MidmarkTypography sx={{ mb: 3.25 }} variant="body1">
                        {description}
                    </MidmarkTypography>
                )}

                {children}

                {buttons && (
                    <Grid
                        container
                        direction="row"
                        wrap="nowrap"
                        justifyContent="space-between"
                        justifySelf="flex-end"
                        alignItems="center"
                        sx={{
                            marginTop: 'auto',
                            paddingTop: 2.5,
                            '& > *:last-child': { marginLeft: 'auto' },
                        }}
                    >
                        {buttons}
                    </Grid>
                )}
            </Grid>
        </StyledForm>
    )
}

const DATA_TESTIDS = {
    formTitleTextField: 'form-title-text-field',
}
