import PropTypes from 'prop-types'
import { Box, styled, Typography } from '@mui/material'

import SuccessGraphic from '@/img/icons/Success Graphic.svg'
import ErrorGraphic from '@/img/icons/Error Graphic.svg'
import WarnGraphic from '@/img/icons/Warn Graphic.svg'

const StyledImg = styled('img')({
    position: 'absolute',
    top: '65px',
    right: '-20px',
    background: 'white',
    borderRadius: '20px',
})

type IconWithTitleAndStatusProps = {
    iconSrc: string
    title: string
    status?: 'Success' | 'Error' | 'Warn'
} & React.HTMLAttributes<HTMLDivElement>

/**
 * A component to render an icon with a title. Optionally the icon can have a status specified which will overlay
 * the primary icon with a secondary smaller status icon
 *
 * @param {{iconSrc: string, title: string, status: 'Success' | 'Error' | 'Warn'}, ...otherProps} props
 * @returns
 */
const IconWithTitleAndStatus = ({
    iconSrc,
    title,
    status,
    ...otherProps
}: IconWithTitleAndStatusProps) => {
    return (
        <>
            <Box
                sx={(theme) => ({
                    position: 'relative',
                    margin: theme.spacing(4),
                    marginBottom: theme.spacing(2),
                    minWidth: '90px',
                    maxWidth: '90px',
                    minHeight: '90px',
                    '& .User-graphic': {
                        maxWidth: '100%',
                        minWidth: '100%',
                    },
                })}
                data-status={status}
                {...otherProps}
            >
                <img src={iconSrc} className="User-graphic" alt="" />
                {status != null &&
                    (status === 'Success' ? (
                        <StyledImg alt="" src={SuccessGraphic} />
                    ) : status === 'Warn' ? (
                        <StyledImg alt="" src={WarnGraphic} />
                    ) : (
                        <StyledImg
                            alt=""
                            sx={{ background: 'unset', borderRadius: 'unset' }}
                            src={ErrorGraphic}
                        />
                    ))}
            </Box>
            <Typography variant="subtitle1" color="primary">
                {title}
            </Typography>
        </>
    )
}

IconWithTitleAndStatus.propTypes = {
    iconSrc: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    status: PropTypes.oneOf(['Success', 'Error', 'Warn']),
}

export default IconWithTitleAndStatus
