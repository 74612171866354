import { BrowserRouter as Router } from 'react-router-dom'
import { createRoot } from 'react-dom/client'
import App from '@/App'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import {
    MidmarkV5CompatTheme,
    UserRegistrationServiceContext,
    UsersRegistrationApiService,
} from '@midmark-enterprise/shared-components'
import { QueryClient, QueryClientProvider } from 'react-query'
import { UserVerificationServiceContext } from './services/UserVerificationService/UserVerificationService'
import { UserVerificationServiceApi } from './services/UserVerificationService/Implementations/UserVerificationServiceApi'

const queryClient = new QueryClient()
const userVerificationApiService = new UserVerificationServiceApi()
const userRegistrationApiService = new UsersRegistrationApiService(
    import.meta.env.VITE_APIM_URL +
        import.meta.env.VITE_USERSREGISTRATION_ROUTE,
    '0',
    import.meta.env.VITE_MIDMARK_API_SCOPE_BASE,
)

const container = document.getElementById('root')
const root = createRoot(container as HTMLElement)

root.render(
    <>
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={MidmarkV5CompatTheme}>
                <QueryClientProvider client={queryClient}>
                    <UserVerificationServiceContext.Provider
                        value={userVerificationApiService}
                    >
                        <UserRegistrationServiceContext.Provider
                            value={userRegistrationApiService}
                        >
                            <Router>
                                <App />
                            </Router>
                        </UserRegistrationServiceContext.Provider>
                    </UserVerificationServiceContext.Provider>
                </QueryClientProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    </>,
)
