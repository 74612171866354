import { Box, useTheme } from '@mui/material'
import { createContext, useCallback, useContext, useState } from 'react'
import EmailVerificationStep from './Steps/EmailVerificationStep.component'
import SignUpForm, { UserRegistrationFormData } from './Steps/SignUpForm'
import AccountCreatedStep from './Steps/AccountCreatedStep.component'
import { UserRegistrationService } from '@midmark-enterprise/shared-components'

export const UserRegistrationDataContext = createContext<{
    userRegistrationData: UserRegistrationFormData
    setUserRegistrationData: React.Dispatch<
        React.SetStateAction<UserRegistrationFormData>
    >
    initialEmailVerificationCodeSent: boolean
    setInitialEmailVerificationCodeSent: React.Dispatch<
        React.SetStateAction<boolean>
    >
    emailVerificationCode: string
    setEmailVerificationCode: React.Dispatch<React.SetStateAction<string>>
} | null>(null)
UserRegistrationDataContext.displayName = 'UserRegistrationDataContext'

const ERROR_TEXT =
    'useSimplePager can only be used within a UserSignupWizard component'

export const useUserRegistrationDataContext = () => {
    const context = useContext(UserRegistrationDataContext)
    if (context == null) {
        throw new Error(ERROR_TEXT)
    }
    return context
}

export enum UserSignupWizardPages {
    profileInformation,
    emailVerification,
    companyInformation,
    accountCreated,
}

interface NavigationRule {
    page: UserSignupWizardPages
    allowedBack: UserSignupWizardPages[]
    allowedForward: UserSignupWizardPages[]
}

const navigationRules: NavigationRule[] = [
    {
        page: UserSignupWizardPages.profileInformation,
        allowedBack: [],
        allowedForward: [UserSignupWizardPages.emailVerification],
    },
    {
        page: UserSignupWizardPages.emailVerification,
        allowedBack: [UserSignupWizardPages.profileInformation],
        allowedForward: [UserSignupWizardPages.accountCreated],
    },
    {
        page: UserSignupWizardPages.accountCreated,
        allowedBack: [],
        allowedForward: [],
    },
]

export default function UserSignupWizard(): JSX.Element {
    const theme = useTheme()

    const [currentPage, setCurrentPage] = useState(
        UserSignupWizardPages.profileInformation,
    )
    const [
        initialEmailVerificationCodeSent,
        setInitialEmailVerificationCodeSent,
    ] = useState(false)
    const [userRegistrationData, setUserRegistrationData] = useState(
        {} as UserRegistrationFormData,
    )
    const [emailVerificationCode, setEmailVerificationCode] = useState('')
    const userRegistrationValue = {
        userRegistrationData,
        setUserRegistrationData,
        initialEmailVerificationCodeSent,
        setInitialEmailVerificationCodeSent,
        emailVerificationCode,
        setEmailVerificationCode,
    }
    const registerUserMutation =
        UserRegistrationService.useRegisterUserMutation()

    const nextPage = (pageRequested: UserSignupWizardPages): void => {
        if (
            navigationRules
                .find((x) => x.page === currentPage)
                ?.allowedForward.some((page) => page === pageRequested)
        ) {
            setCurrentPage(pageRequested)
        }
    }

    const previousPage = (pageRequested: UserSignupWizardPages): void => {
        if (
            navigationRules
                .find((x) => x.page === currentPage)
                ?.allowedBack.some((page) => page === pageRequested)
        ) {
            setCurrentPage(pageRequested)
        }
    }

    const registerUser = useCallback(
        (id: string, code: string) => {
            registerUserMutation
                .mutateAsync({
                    userData: userRegistrationData ?? {},
                    id: id,
                    code: code,
                })
                .then(() => {
                    nextPage(UserSignupWizardPages.accountCreated)
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        [userRegistrationData, emailVerificationCode, registerUserMutation],
    )

    const handleSignUpFormSubmit = (
        userData: UserRegistrationFormData,
    ): void => {
        setUserRegistrationData(userData)
        setInitialEmailVerificationCodeSent(false)
        nextPage(UserSignupWizardPages.emailVerification)
    }

    const handleEmailVerificationSubmit = (id: string, code: string): void => {
        registerUser(id, code)
        nextPage(UserSignupWizardPages.accountCreated)
    }

    return (
        <UserRegistrationDataContext.Provider value={userRegistrationValue}>
            <Box
                sx={{
                    position: 'relative',
                    overflowX: 'hidden',
                    overflowY: 'hidden',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {currentPage === UserSignupWizardPages.profileInformation && (
                    <SignUpForm handleSubmit={handleSignUpFormSubmit} />
                )}

                {currentPage === UserSignupWizardPages.emailVerification && (
                    <EmailVerificationStep
                        handleBackButton={previousPage}
                        handleForwardButton={handleEmailVerificationSubmit}
                    />
                )}

                {currentPage === UserSignupWizardPages.accountCreated && (
                    <AccountCreatedStep />
                )}
            </Box>
        </UserRegistrationDataContext.Provider>
    )
}
