import { useState } from 'react'
import { Outlet } from 'react-router-dom'
import {
    Box,
    Grid,
    Hidden,
    Paper,
    styled,
    Theme,
    useTheme,
} from '@mui/material'

import useMediaQuery from '@mui/material/useMediaQuery'

import logoBackground from '@/img/logo-background.svg'
import backgroundPlaceholder from '@/img/background/password-reset-background.png?width=40&format=jpeg&imagetools'
import background from '@/img/background/password-reset-background.png?format=jpeg&imagetools'
import backgroundSrcSet from '@/img/background/password-reset-background.png?w=500;900;1200&format=jpeg&srcset'
import backgroundWebPSrcSet from '@/img/background/password-reset-background.png?format=webp&srcset'
import CssBaseline from '@mui/material/CssBaseline'

const Logo = styled('img')({
    position: 'absolute',
    bottom: '0',
    left: '0',
})

const BackgroundImage = styled('img')({
    pointerEvents: 'none',
    position: 'absolute',
    objectFit: 'cover',
    minWidth: '100%',
    maxWidth: '100%',
    minHeight: '100%',
    maxHeight: '100%',
})

const BackgroundBlur = styled(BackgroundImage)({
    transform: 'scale(1.1)',
    transition: 'opacity .5s ease-in',
    filter: 'blur(20px)',
})

const MainGrid = styled(Grid)(({ theme }) => ({
    minWidth: '95%',
    [theme.breakpoints.up('sm')]: {
        minWidth: 400,
        maxWidth: 400,
    },
}))

const StyledPaper = styled(Paper)(({ theme }) => ({
    minHeight: 425,
    padding: '20px',
    paddingTop: '15px',
    paddingBottom: '10px',
    textAlign: 'center',
    maxWidth: '97%',
    boxSizing: 'border-box',
    [theme.breakpoints.down('md')]: {
        margin: '0px 5px',
    },
    zIndex: 100,
}))

export const MidmarkV3Layout = () => {
    const [backgroundLoaded, setBackgroundLoaded] = useState(false)
    const theme = useTheme()
    const isSmallScreen = useMediaQuery<Theme>(theme.breakpoints.down('sm'), {
        defaultMatches: true,
    })

    return (
        <>
            <CssBaseline />
            <Box
                sx={{
                    overflow: 'hidden',
                    position: 'relative',
                    width: '100%',
                }}
            >
                <picture>
                    <source data-testid="background-source" srcSet={backgroundWebPSrcSet} type="image/webp" />
                    <BackgroundImage
                        data-testid="background-image"
                        onLoad={() => setBackgroundLoaded(true)}
                        srcSet={backgroundSrcSet}
                        src={background.src}
                        alt=""
                    />
                </picture>
                <BackgroundBlur
                    data-testid="background-image-blur"
                    style={{ opacity: backgroundLoaded ? 0 : 1 }}
                    src={backgroundPlaceholder.src}
                    alt=""
                />
                <Hidden only={['xs']}>
                    <Logo src={logoBackground} alt="" />
                </Hidden>
                <Grid
                    sx={{
                        flexGrow: 1,
                        height: '100vh',
                    }}
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <MainGrid item>
                        <Grid
                            item
                            xl={10}
                            xs={12}
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="stretch"
                            style={{ maxWidth: '100%' }}
                        >
                            <StyledPaper
                                elevation={isSmallScreen ? 0 : 1}
                                role="main"
                            >
                                <Outlet />
                            </StyledPaper>
                        </Grid>
                    </MainGrid>
                </Grid>
            </Box>
        </>
    )
}

export default MidmarkV3Layout
