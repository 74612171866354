/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/prop-types */
/* eslint-disable i18next/no-literal-string */

import i18n from 'i18next'

import { initReactI18next } from 'react-i18next'

import en from './public/locales/en/login-extensions.json'
import fr from './public/locales/fr/login-extensions.json'

i18n.use(initReactI18next).init({
    resources: {
        en: { translation: en },
        fr: { translation: fr },
    },
    lng: 'en', // default language
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false, // not needed for react!!
    },
})

export default i18n